/**
 * Class for handle login page actions
 */
export class LoginPage {
  constructor() {
    this.init();
  }

  init() {
    this.form = document.getElementById('logon-form');
    this.inputLang = document.getElementById('_login-lang-val');
    this.inputTheme = document.getElementById('_login-theme-val');
    this.inputUsername = document.getElementById('username');
    this.inputPwd = document.getElementById('password');
  }

  /**
   * Hengle language select
   */
  selectHandler(e, data) {
    var id = data.id,
      lang,
      theme,
      username,
      passwd,
      param,
      elem,
      form,
      keyVar,
      wrapper;
    if (id === '_login-theme-val' || id === '_login-lang-val') {
      lang = this.inputLang.value;
      theme = this.inputTheme ? this.inputTheme.value : undefined;
      passwd = this.inputPwd.value;
      username = this.inputUsername.value;
      param = {
        func: 'logon',
        lang: lang,
        theme: theme,
        username: username,
        pwd: passwd,
      };
      form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('action', pageInfo.url);
      /* jslint forin:true */
      for (keyVar in param) {
        elem = document.createElement('input');
        elem.setAttribute('type', 'hidden');
        elem.setAttribute('name', keyVar);
        elem.setAttribute('value', param[keyVar]);
        form.appendChild(elem);
      }
      wrapper = document.getElementById('hiddenform');
      wrapper.appendChild(form);
      form.submit();
    }
  }

  /**
   * Handle form submitting
   */
  submitLoginForm(e) {
    e = e || window.event;
    var ok = true;
    if (this.inputUsername.value === '') {
      this.inputUsername.setAttribute('invalid', 'true');
      ok = false;
    } else {
      this.inputUsername.removeAttribute('invalid');
    }
    if (this.inputPwd.value === '') {
      this.inputPwd.setAttribute('invalid', 'true');
      ok = false;
    } else {
      this.inputPwd.removeAttribute('invalid');
    }
    if (!ok) {
      e.preventDefault();
    }
  }
}
